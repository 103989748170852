import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {tileGrid} from "./Common";
import {DEFAULT_FEATURE_STROKE_COLOR} from "../constants";

let drawLayerSource = new VectorSource({
    tileGrid: tileGrid,
    wrapX: false
});
export const drawLayer = new VectorLayer({
    source: drawLayerSource,
    style: {
        'stroke-color': DEFAULT_FEATURE_STROKE_COLOR,
        'stroke-width': 5,
        'circle-radius': 10,
    },
})