import {
    handleDisableEditMode,
    handleDisableViewMode,
    handleEnableEditMode, handleEnableViewMode,
    handleTriggerViewMode,
    removeInteractions
} from "../helpers/UIHelpers";
import {handleOnDiscardCancelled, handleOnDiscardClicked} from "../state/transitions";
import {
    ON_CLICK_CANCEL_DISCARD_CHANGES,
    ON_CLICK_CONFIRM_DISCARD_CHANGES,
    ON_DISCARD_CHANGES_CLICKED
} from "../event-constants";

export function initializeDiscardChangesDialog(){
    $('#confirm-discard-changes').click(handleOnDiscardClicked);
    $('#cancel-discard-changes').click(handleOnDiscardCancelled);
    $(document).on(ON_DISCARD_CHANGES_CLICKED, handleToggleDiscardConfirmationMenu);
    $(document).on(ON_CLICK_CONFIRM_DISCARD_CHANGES, handleConfirmDiscardChanges);
    $(document).on(ON_CLICK_CANCEL_DISCARD_CHANGES, handleCancelDiscardChanges);
}

export function handleDiscardChanges(){
    removeInteractions();

    handleTriggerViewMode();

    handleConfirmDiscardChanges();
}

// Show the discard confirm dialog menu
function handleToggleDiscardConfirmationMenu(){
    let discard_menu = $('#discard-changes')

    discard_menu.prop('open', true);

    handleDisableEditMode();
    handleDisableViewMode();
    $('#coordinate-search').addClass('hidden');
    $('#coordinate-search-menu').addClass('hidden');
}

// handle if the user presses the cancel button
function handleCancelDiscardChanges(){
    handleCloseDiscardConfirmationMenu();
    handleDisableViewMode();
    handleEnableEditMode();
    $('#coordinate-search').removeClass('hidden');
}

// handle if the user presses the discard button
function handleConfirmDiscardChanges(){
    handleCloseDiscardConfirmationMenu();
    handleDisableEditMode();
    handleEnableViewMode();
    $('#coordinate-search').removeClass('hidden');
}

// handle closing the discard dialog
function handleCloseDiscardConfirmationMenu(){
    let discard_menu = $('#discard-changes')

    discard_menu.prop('open', false);
}