import {transform} from 'ol/proj';
import {map} from '../Map';
import Handlebars from "handlebars";
import {addPointToLayer, removePointFromLayer} from "../layers/PointLayer";
import {HIDE_SEARCH_RESULTS, SHOW_SEARCH_RESULTS} from "../event-constants";

$.widget("custom.coordinateSearch", {
    options: {
        template: "{{> coordinate-search-menu}}",
        templateID: "coordinate-search-container",
        zoomLevel: 8,
        dialogTemplate: "{{> dialog-template}}",
        dialogTitle: "Enter Coordinates"
    },

    _create: function(){
        // Initialize the coordinate search control.
        let template = Handlebars.compile(this.options.template);
        let htmlContent = template();

        let dialogTemplate = Handlebars.compile(this.options.dialogTemplate);
        let dialogContent = dialogTemplate({
            dialogID: this.options.templateID,
            dialogTitle: this.options.dialogTitle,
            dialogContent: htmlContent
        });

        $('#dialog-container').append(dialogContent);

        this.closeDialog();

        // Add event listeners to the component
        this._on('#coordinate-search', {
            click: this._handleClickCoordinateSearch
        });

        this._on(`#${this.options.templateID}`, {
            'click #close-dialog': this.closeDialog
        });

        this._on("#go-to-coordinate", {
            click: this._handleSearchCoordinates
        });

        this._on("#latitude-coordinate", {
            input: function(e){
                this._enableCoordinateSearchIfInputFilled(e);
                this._validateCoordinateInput(e);
            }
        });

        this._on("#longitude-coordinate", {
            input: function(e){
                this._enableCoordinateSearchIfInputFilled(e);
                this._validateCoordinateInput(e);
            }
        });

        this._on("#top-controls", {
            'click': this._placeCoordinateSearchMenu,
            [SHOW_SEARCH_RESULTS]: this._placeCoordinateSearchMenu,
            [HIDE_SEARCH_RESULTS]: this._placeCoordinateSearchMenu
        });

        this._on("#dialog-container", {
            'click': this._placeCoordinateSearchMenu
        });
    },

    _enableCoordinateSearchIfInputFilled: function(){
        let latitude = $('#latitude-coordinate').val();
        let longitude = $('#longitude-coordinate').val();
        if(latitude && longitude){
            $('#go-to-coordinate').prop('disabled', false);
        }else{
            $('#go-to-coordinate').prop('disabled', true);
        }
    },

    _validateCoordinateInput: function(e){
        if(isNaN(e.target.value)){
            return;
        }

        let value = Number(e.target.value);
        let min = Number(e.target.min);
        let max = Number(e.target.max);

        if(value > max){
            $(e.target).val(e.target.max);
        }else if(value < min){
            $(e.target).val(e.target.min);
        }
    },

    _handleClickCoordinateSearch: function(){
        if($('#coordinate-search').prop('toggle')){
            // if the coordinate search is toggled, untoggle it
            this._toggleCoordinateSearch();
            // remove the point from the map
            removePointFromLayer();
        }else{
            // show the coordinate search dialog
            this._handleOpenCoordinateSearchDialog();
        }
    },

    _handleSearchCoordinates: function(){
        // get the longitude and latitude
        let latitude = $('#latitude-coordinate').val();
        let longitude = $('#longitude-coordinate').val();

        // navigate to the given latitude and longitude
        this._navigateToLatLng(latitude, longitude);
        // toggle the target button to show we've searched
        this._toggleCoordinateSearch();
        // hide the coordinate search dialog
        this.closeDialog();
    },

    _toggleCoordinateSearch: function() {
        let coordinateSearchElement = $('#coordinate-search');
        if(coordinateSearchElement.prop('toggle')){
            coordinateSearchElement.prop('selected', false);
            coordinateSearchElement.prop('toggle', false);
        }else{
            coordinateSearchElement.prop('selected', true);
            coordinateSearchElement.prop('toggle', true);
        }
    },

    _navigateToLatLng: function(lat,lng){
        // get coordinates of the given latitude and longitude.
        let coords = transform([lng, lat], 'EPSG:4326', 'EPSG:3857');
        // set the zoom level of the map.
        map.getView().setZoom(this.options.zoomLevel);
        // center the map on the location
        map.getView().setCenter(coords);

        // add a point to the map at the given coordinates
        addPointToLayer(coords);
    },

    _handleOpenCoordinateSearchDialog: function(){
        this._show($(`#${this.options.templateID}`));
    },

    closeDialog: function(){
        this._hide($(`#${this.options.templateID}`));
    },

    _placeCoordinateSearchMenu: function(){
        // get all visible divs in dialog-container, if more than one, remove center under button class, otherwise add it
        let visibleDivs = $('#dialog-container').children('div:visible');
        let visibleButtons = $('#top-controls div').children(':visible');
        if(visibleDivs.length > 1){
            $(`#${this.options.templateID}`).removeClass('align-coordinate-search-under-button');
        }else if(visibleButtons.length === 1) {
            $(`#${this.options.templateID}`).removeClass('align-coordinate-search-under-button');
        }else{
            $(`#${this.options.templateID}`).addClass('align-coordinate-search-under-button');
        }
    }
});

export const coordinateSearch = $('#dialog-container').coordinateSearch({}).data("custom-coordinateSearch");