import {ATTRIBUTE_LABELS, ENUM_TYPES, SELECTED_LANGUAGE_CODE, SERVER_PROCESSING_EVENT_MESSAGE} from "../constants";
import {callAPI} from "../model/Request";
import {editor, prepareEventAttributesForm} from "./EventAttributesModal";
import {ERROR_UPDATE_ATTRIBUTES, ON_SAVE_SUCCESS,} from "../event-constants";
import {getEventData} from "./ViewEvent";
import {createPopupOptions, renderInfoMessage} from "../control/InfoPopup";
import {getEventAttributes} from "./EventAttributes";
import {eventTypes} from "../control/NewEvent";

// constants defined for file
const UPDATE_MODAL_HEADER = 'Update Event';
const UPDATE_MODAL_ID = 'update-event-details';
const EVENT_NAME_ID = 'event-name';
const EVENT_COLOR_ID = 'create-event-color';
const EVENT_DESCRIPTION = 'description';
const EVENT_YEAR_ID = 'event-year';

let freeFormAttributes = [];

export function handleEditEventClicked (){
    // get event data
    let eventData = getEventData();

    renderEditEventAttributes(eventData);

    $('#update-event-details').removeClass('hidden');
    $('#view-event-details').addClass('hidden');
}

function getUpdatedAttributes(){
    let attributeElements = $('.attribute-input')
    let attributeObject = {};

    // get attribute data and build its object to return
    Array.from(attributeElements).forEach((attributeElement) => {
        let value = attributeElement.value === null ? "" : attributeElement.value;
        attributeObject[attributeElement.id] = {
            [SELECTED_LANGUAGE_CODE]: value,
            type: attributeElement.type,
        }
    })

    return attributeObject;
}

async function handleSaveEventData(){
    let eventData = getEventData();

    let description = editor.getData();

    let attributes = getUpdatedAttributes();

    let event_group_id = eventData.event_group_id;

    let tileDelta = {
        'delete': [],
        'new': []
    }

    let updatedEventData = {
        id: eventData.id,
        event_group_id: event_group_id,
        description: description,
        attributes: attributes,
        tiles: tileDelta
    };

    let fields = {
        'name': EVENT_NAME_ID,
        'color': EVENT_COLOR_ID
    };

    Object.entries(fields).forEach(([name, field]) => {
        updatedEventData[name] = $(`#${field}`).val();
    })

    return await callAPI('edit-event', updatedEventData, [])
        .then(() => {
            $(document).trigger($.Event(ON_SAVE_SUCCESS));
            renderInfoMessage(createPopupOptions({text: SERVER_PROCESSING_EVENT_MESSAGE}));
        })
        .catch((err) => {
            $(document).trigger($.Event(ERROR_UPDATE_ATTRIBUTES, {error: err}));
        })
}

// render input fields for updating event data
function renderEventUpdateFields(event){
    let eventType = eventTypes.find((type) => type.id === Number(event.event_type_id));
    // deep copy the colors array to avoid modifying the original array
    let availableColors = JSON.parse(JSON.stringify(eventType.colors));
    for(let color of availableColors){
        if(color.color === event.color_hex){
            color.selected = true;
        }
    }
    let isColorDisabled = availableColors.length === 1;
    return {
        modalHeader: UPDATE_MODAL_HEADER,
        modalID: UPDATE_MODAL_ID,
        isEdit: true,
        colors: availableColors,
        selectedColor: event.color_hex,
        isColorDisabled,
        description: event.description,
        language: SELECTED_LANGUAGE_CODE
    };
}

// generate input elements for every attribute for this event type.
function renderUpdateEventAttributesInput(event){
    let attributeObject = [];

    attributeObject.push({name: EVENT_NAME_ID, type: ENUM_TYPES['text'], label: ATTRIBUTE_LABELS['Name'], value: event.name, isPermanent: true})
    attributeObject.push({name: EVENT_DESCRIPTION, type: ENUM_TYPES['rich-text'], label: ATTRIBUTE_LABELS['Description'], isPermanent: true})
    attributeObject.push({name: EVENT_YEAR_ID, type: ENUM_TYPES['number'], label: ATTRIBUTE_LABELS['Year'], value: event.year, isPermanent: true, readOnly: true, isYear: true})

    let eventTypeID = event.event_type_id;

    freeFormAttributes = getEventAttributes(eventTypeID);

    freeFormAttributes.forEach((attribute) => {
        let attributeValue = '';
        if(Object.keys(event.attributes).includes(attribute.name)){
            attributeValue = event.attributes[attribute.name][SELECTED_LANGUAGE_CODE];
        }
        attributeObject.push({
            name: attribute.name,
            type: attribute.type,
            label: attribute.label,
            value: attributeValue,
            isPermanent: false
        })
    });

    return attributeObject;
}

export function handleUpdateAttributes(){
    // call save handler
    // after 'saving' go back to view event data with updated data
    handleSaveEventData()
        .catch(()=>{
            handleEditEventClicked();
        })
}

function renderEditEventAttributes(event){
    let templateObject = renderEventUpdateFields(event);
    templateObject.attributes = renderUpdateEventAttributesInput(event);

    prepareEventAttributesForm(templateObject);
}