import {
    handleDisableEditMode,
    handleEnableEditMode, clearScreen, handleTriggerEditMode,
    handleTriggerViewMode,
    removeInteractions, disableLassoTool, enableLassoTool
} from "../helpers/UIHelpers";
import {clearEvents, areFeaturesPresent, handlePaintLine, enableEditMode} from "../features";
import {
    DELETE_CONFIRMATION_STATE,
    DISCARD_CHANGES_STATE,
    EDIT_EVENT_ATTRIBUTES_STATE,
    FREE_FORM_DRAW_STATE,
    INSERT_EVENT_DETAILS_STATE,
    PAINT_STATE,
    SAVING_ATTRIBUTES_STATE,
    SAVING_TIlES_STATE,
    SHAPE_CLOSED_STATE,
    TRANSITION_GRAPH,
    VIEW_EVENT_DETAILS_STATE,
    VIEW_EVENTS_STATE
} from "./constants";
import {closeViewEventHandler, getEventData, getEventID} from "../events/ViewEvent";
import {handleUpdateEvent} from "../events/EditEventTiles";
import {handleEditEventClicked, handleUpdateAttributes} from "../events/EditEventAttributes";
import {handleSelectTileOn} from "../control/SelectTile";
import {hideFeaturePopup, renderFeaturePopup} from "../control/FeaturePopup";
import {hideEventAttributesModal} from "../events/EventAttributesModal";
import {renderNewEventModal, handleSaveEvent} from "../events/NewEventAttributes";
import {handleDiscardChanges} from "../control/DiscardChanges";
import {
    ON_CANCEL_DISCARD_CHANGES_TO_TILES, ON_CANCEL_DISCARD_EVENT_ATTRIBUTE_CHANGES,
    ON_CANCEL_DISCARD_NEW_EVENT, ON_CLICK_CANCEL_DISCARD_CHANGES, ON_CONFIRM_DELETE_EVENT,
    ON_CONFIRM_DISCARD_CHANGES_TO_TILES, ON_CONFIRM_DISCARD_EVENT_ATTRIBUTE_CHANGES,
    ON_CONFIRM_DISCARD_NEW_EVENT,
    ON_CREATE_SAVE_PRESSED, ON_DISCARD_CHANGES_CLICKED,
    ON_SAVE_PRESSED
} from "../event-constants";
import {eventLayer} from "../layers/Event";
import {restrictMapWidth, resetMapWidth} from "../Map";
import {refreshEventLayer} from "../layers/EventTileWMS";
import {editWMSLayer} from "../layers/EditTileWMS";

// default state on startup is VIEW_STATE≤
let state = VIEW_EVENTS_STATE;
let previousState = '';

// function to set a new state (after checking if valid?)
export function setState(event){
    if(TRANSITION_GRAPH[event.type].from.includes(state)){
        // start transitioning if new state is applied
        transition(TRANSITION_GRAPH[event.type].to, event);
    }
}

const transitions = {
    [VIEW_EVENTS_STATE]: {transition: viewEventsTransition, changePointerOnHover: true, selectEventOnClick: true},
    [SAVING_TIlES_STATE]: {transition: saveTilesTransition, changePointerOnHover: true, selectEventOnClick: true},
    [SAVING_ATTRIBUTES_STATE]: {transition: saveAttributesTransition, changePointerOnHover: true, selectEventOnClick: true},
    [FREE_FORM_DRAW_STATE]: {transition: freeFormDrawTransition, changePointerOnHover: false, selectEventOnClick: false},
    [SHAPE_CLOSED_STATE]: {transition: shapeClosedTransition, changePointerOnHover: false, selectEventOnClick: false},
    [INSERT_EVENT_DETAILS_STATE]: {transition: insertEventDetailsTransition, changePointerOnHover: false, selectEventOnClick: false},
    [DISCARD_CHANGES_STATE]: {transition: discardChangesTransition, changePointerOnHover: true, selectEventOnClick: true},
    [VIEW_EVENT_DETAILS_STATE]: {transition: viewEventDetailsTransition, changePointerOnHover: true, selectEventOnClick: true},
    [EDIT_EVENT_ATTRIBUTES_STATE]: {transition: editEventAttributesTransition, changePointerOnHover: false, selectEventOnClick: false},
    [PAINT_STATE]: {transition: paintStateTransition, changePointerOnHover: false, selectEventOnClick: false},
    [DELETE_CONFIRMATION_STATE]: {transition: deleteConfirmationTransition, changePointerOnHover: true, selectEventOnClick: true}
}

function transition(newState, event){
    // handle the actual transition of the states by using the state variable
    try{
        transitions[newState].transition(state, newState, event);
        previousState = state;
        state = newState;
    }catch(e){
        // TODO call fallback function if an error was caught
        console.log(e);
    }
}

// depending on the previous state handle how the map save button transitions into another state
export function handleSaveButtonPressed(){
    let eventToTrigger = '';

    let newEventSaveStates = [FREE_FORM_DRAW_STATE, SHAPE_CLOSED_STATE];

    if(newEventSaveStates.includes(previousState) || newEventSaveStates.includes(state) && areFeaturesPresent()){
        eventToTrigger = ON_CREATE_SAVE_PRESSED
    }else if(state === PAINT_STATE){
        eventToTrigger = ON_SAVE_PRESSED
    }

    $(document).trigger(eventToTrigger);
}

export function handleOnDiscardClicked(){
    let eventToTrigger = '';

    if(previousState === FREE_FORM_DRAW_STATE || previousState === SHAPE_CLOSED_STATE){
        eventToTrigger = ON_CONFIRM_DISCARD_NEW_EVENT;
    }else if(previousState === PAINT_STATE){
        eventToTrigger = ON_CONFIRM_DISCARD_CHANGES_TO_TILES;
    }else if(previousState === EDIT_EVENT_ATTRIBUTES_STATE){
        eventToTrigger = ON_CONFIRM_DISCARD_EVENT_ATTRIBUTE_CHANGES;
    }

    $(document).trigger(eventToTrigger);
}

export function handleOnDiscardCancelled(){
    let eventToTrigger = '';

    if(previousState === FREE_FORM_DRAW_STATE || previousState === SHAPE_CLOSED_STATE){
        eventToTrigger = ON_CANCEL_DISCARD_NEW_EVENT;
    }else if(previousState === PAINT_STATE){
        eventToTrigger = ON_CANCEL_DISCARD_CHANGES_TO_TILES;
    }else if(previousState === EDIT_EVENT_ATTRIBUTES_STATE){
        eventToTrigger = ON_CANCEL_DISCARD_EVENT_ATTRIBUTE_CHANGES;
    }

    $(document).trigger(eventToTrigger);
}

/**
 * Handle the transition to view events state
 *
 * @param previousState previous state of system
 * @param state current state of system
 */
export function viewEventsTransition(previousState, state, event){
    if(event.type === ON_CONFIRM_DELETE_EVENT){
        refreshEventLayer();
    }

    if(previousState === DISCARD_CHANGES_STATE){
        // if coming from discard changes state, we remove the discard popup, and render all view mode items=
        handleDiscardChanges();
        resetForm();
    }else if(previousState === VIEW_EVENT_DETAILS_STATE){
        // if coming from view event details state, close the view event details modal
        closeViewEventHandler();
    }else if(previousState === DELETE_CONFIRMATION_STATE){
        // if coming from delete confirmation state, close view event details modal, close the delete dialog
        $('#delete-confirmation').confirmDelete("hide");
        $('#coordinate-search').removeClass('hidden');
        closeViewEventHandler();
    }else if(previousState === SAVING_ATTRIBUTES_STATE){
        // if coming from saving attributes state, hide the view event details modal from the screen
        hideEventAttributesModal();
        closeViewEventHandler();
        resetForm();
        clearEvents();
    }else if(previousState === SAVING_TIlES_STATE){
        clearEvents();
        editWMSLayer.setVisible(false);
    }

    resetMapWidth();
    hideFeaturePopup();
    handleTriggerViewMode();
    resetMapEvents();
    

    return true;
}

/**
 * Call function to save tiles when in draw mode
 *
 * @param previousState previous state of system
 * @param state current state of system
 */
export function saveTilesTransition(previousState, state){
    // disable all interactions on the map
    handleDisableEditMode();
    removeInteractions();

    // Save tiles on map
    let featureId = getEventID();
    let eventData = getEventData();

    handleUpdateEvent(featureId, eventData);
    refreshEventLayer();
    resetMapWidth();
    
    return true;
}

/**
 * Depending on previous state, call function to save a new events attributes, or save an edited events attributes
 *
 * @param previousState previous state of system
 * @param state current state of system
 */
export function saveAttributesTransition(previousState, state){
    if(previousState === INSERT_EVENT_DETAILS_STATE){
        handleSaveEvent();
        hideEventAttributesModal();
    }else if(previousState === EDIT_EVENT_ATTRIBUTES_STATE){
        handleUpdateAttributes();
    }

    hideFeaturePopup();
    resetMapWidth();
    
    return true;
}

/**
 * Transition into free form draw state
 *
 * @param previousState previous state of system
 * @param state current state of system
 */
export function freeFormDrawTransition(previousState, state){
    if(previousState === INSERT_EVENT_DETAILS_STATE){
        handleEnableEditMode();
        hideEventAttributesModal();
    }

    if(previousState === DISCARD_CHANGES_STATE){
        $(document).trigger(ON_CLICK_CANCEL_DISCARD_CHANGES);
    }

    toggleSaveButton();
    enableLassoTool();
    handleTriggerEditMode()
    handlePaintLine();
    hideFeaturePopup();
    resetMapWidth();

    
    return true;
}

/**
 * Transition into shape closed state
 *
 * @param previousState previous state of system
 * @param state current state of system
 */
export function shapeClosedTransition(previousState, state, event){
    if(previousState === DISCARD_CHANGES_STATE){
        $(document).trigger(ON_CLICK_CANCEL_DISCARD_CHANGES);
    }

    toggleSaveButton();

    renderFeaturePopup(event.featureId, event.x, event.y, false);
    resetMapWidth();
    
    return true;
}

/**
 * Transition into insert event details state
 *
 * @param previousState previous state of system
 * @param state current state of system
 */
export async function insertEventDetailsTransition(previousState, state, event){
    // change insert event to default values
    // show popup to insert details
    hideFeaturePopup();
    handleDisableEditMode();
    restrictMapWidth();
    if(previousState === SAVING_ATTRIBUTES_STATE){
        $('#insert-event-details').removeClass('hidden');
    }else{
        renderNewEventModal();
    }

    
    return true;
}

/**
 * Transition into discard changes state, show the discard changes dialog to the user
 *
 * @param previousState previous state of system
 * @param state current state of system
 */
export function discardChangesTransition(previousState, state){
    // display confirmation popup to discard all changes
    $(document).trigger(ON_DISCARD_CHANGES_CLICKED);
    hideFeaturePopup();

    if(previousState === EDIT_EVENT_ATTRIBUTES_STATE){
        $("#update-event-details").addClass('hidden');
    }
    resetMapWidth();
    
    return true;
}

/**
 * Transition into view event details state
 *
 * @param previousState previous state of system
 * @param state current state of system
 */
export function viewEventDetailsTransition(previousState, state){
    // Put all event data into popup
    // display popup
    if(previousState === DELETE_CONFIRMATION_STATE){
        $('#delete-confirmation').confirmDelete("hide");
        $('#coordinate-search').removeClass('hidden');
        resetForm();
    }else if(previousState === DISCARD_CHANGES_STATE){
        $(document).trigger(ON_CLICK_CANCEL_DISCARD_CHANGES);
        hideEventAttributesModal();
        resetForm();
        editWMSLayer.setVisible(false);
    }
    $('#view-event-details').removeClass('hidden');
    $('#coordinate-search-menu').addClass('hidden');
    resetMapEvents();
    handleTriggerViewMode();
    
    restrictMapWidth();

    return true;
}

/**
 * Transition into edit event attributes state
 *
 * @param previousState previous state of system
 * @param state current state of system
 */
export function editEventAttributesTransition(previousState, state){
    // put all event data into editable popup
    // display popup
    if(previousState === DISCARD_CHANGES_STATE){
        $(document).trigger($.Event(ON_CLICK_CANCEL_DISCARD_CHANGES));
        handleTriggerViewMode();
        $('#update-event-details').removeClass('hidden');
        $('#sidebar-container').removeClass('hidden');
    }else if(previousState === SAVING_ATTRIBUTES_STATE){
        $('#update-event-details').removeClass('hidden');
    }else{
        handleEditEventClicked();
    }

    restrictMapWidth();
    
    return true;
}

/**
 * Transition into paint state
 *
 * @param previousState previous state of system
 * @param state current state of system
 */
export function paintStateTransition(previousState, state){
    // hide all insert event buttons and default controls
    enableEditMode();
    disableLassoTool();
    $('#save-event-map-button').removeClass('hidden')
    if(previousState === DISCARD_CHANGES_STATE){
        $(document).trigger(ON_CLICK_CANCEL_DISCARD_CHANGES);
    }else if(previousState === SAVING_TIlES_STATE){
        handleEnableEditMode();
        eventLayer.setVisible(true);
    }
    resetMapWidth();
    handleSelectTileOn();
    $('#view-event-details').addClass('hidden');
    

    return true;
}

/**
 * Transition into delete confirmation state
 *
 * @param previousState previous state of system
 * @param state current state of system
 */
export function deleteConfirmationTransition(previousState, state, event){
    // display confirmation popup to discard all changes
    resetMapWidth();
    clearScreen();
    
    $('#view-event-details').addClass('hidden');
    $('#coordinate-search').addClass('hidden');
    $('#delete-confirmation').confirmDelete("show", {deleteAction: event.deleteAction, id: event.id});
    return true;
}

/**
 * Check if save button should be hidden or not
 */
export function toggleSaveButton(){
    if(areFeaturesPresent()){
        // show save button
        $('#save-event-map-button').removeClass('hidden');
    }else{
        // hide save button
        $('#save-event-map-button').addClass('hidden');
    }
}

function resetForm(){
    $('#event-form-template').html('');
}

/**
 * Clear all events from editing layers, and show all database events
 */
function resetMapEvents(){
    removeInteractions();
    clearEvents();
}

export function getCurrentStateProperty(property){
    return transitions[state][property];
}