// control flags
export const FLAG_PAINT = "paint";
export const FLAG_SELECT_TILE = "select-tile";

// constants for transitions
export const ON_EVENT_CLICKED = "onEventClicked";
export const ON_ADD_EVENT_PRESSED = "onAddEventPressed";
export const ON_DRAW_END = "onDrawEnd";
export const ON_FILL_DRAWING = "onFillDrawing";
export const ON_DISCARD_CHANGES = "onDiscardChanges";
export const ON_ESC_KEY_PRESSED = "onEscKeyPressed";
export const ON_CREATE_SAVE_PRESSED = "onCreateSavePressed";
export const ON_EDIT_TILES_PRESSED = "onEditTilesPressed";
export const ON_SAVE_PRESSED = "onSavePressed";
export const ON_SAVE_ATTRIBUTES_PRESSED = "onSaveAttributesPressed";
export const ON_DELETE_EVENT = "onDeleteEvent";
export const ON_CONFIRM_DELETE_EVENT = "onConfirmDeleteEvent";
export const ON_CONFIRM_DISCARD_CHANGES_TO_TILES = "onConfirmDiscardChangesToTiles";
export const ON_CONFIRM_DISCARD_NEW_EVENT = "onConfirmDiscardNewEvent";
export const ON_CONFIRM_DISCARD_EVENT_ATTRIBUTE_CHANGES = "onConfirmDiscardEventAttributeChanges";
export const ON_CANCEL_DISCARD_NEW_EVENT = "onCancelDiscardNewEvent";
export const ON_CANCEL_DISCARD_CHANGES_TO_TILES = "onCancelDiscardChangesToTile";
export const ON_CANCEL_DISCARD_EVENT_ATTRIBUTE_CHANGES = "onCancelDiscardEventAttributeChanges";
export const ON_FREE_FORM_BUTTON_PRESSED = "onFreeFormButtonPressed";
export const ON_CREATE_EVENT_SUCCESS = "onCreateEventSuccess";
export const ON_CREATE_EVENT_FAIL = "onCreateEventFail";
export const ON_CANCEL_INSERT_NEW_EVENT = "onCancelInsertNewEvent";
export const ON_CLOSE_VIEW_EVENT = "onCloseViewEvent";
export const ON_CANCEL_DELETE_EVENT = "onCancelDeleteEvent";
export const ON_ERROR_SAVING_TILES = "onErrorSavingTiles";
export const ON_SAVE_SUCCESS = "onSaveSuccess";
export const ON_EDIT_ATTRIBUTES_PRESSED = "onEditAttributesPressed";
export const ON_EVENT_TYPES_READY = "onEventTypesReady";
export const ON_CLEAR_DRAWING = "on-clear-drawing";
export const ERROR_UPDATE_ATTRIBUTES = "error-update-attributes";

export const YEAR_CHANGED = "year-changed";
export const ON_LAYER_LOADING = "on-layer-loading"; // event for when the event display layer is loading
export const ON_LAYER_LOADED = "on-layer-loaded"; // Event for when event display layer is done loading
export const ON_DISCARD_CHANGES_CLICKED = "on-discard-changes-clicked";
export const ON_CLICK_CONFIRM_DISCARD_CHANGES = "on-click-confirm-discard-changes";
export const ON_CLICK_CANCEL_DISCARD_CHANGES = "on-click-cancel-discard-changes";
export const ON_MAP_CLICK_EVENT = "on-map-click-event";
export const FILTERS_UPDATED = "filters-updated";
export const SHOW_SEARCH_RESULTS = "show-search-results";
export const HIDE_SEARCH_RESULTS = "hide-search-results";
export const MAP_DRAG_OFF = "map-drag-off";
export const MAP_DRAG_ON = "map-drag-on";
export const ON_LAYER_LOADING_ERROR = "on-layer-loading-error";
export const EVENT_SELECTED_FROM_DIALOG = "event-selected-from-dialog";
export const OPACITY_CHANGED = "opacity-changed";
export const GRATICULE_VISIBILITY_CHANGED = "graticule-visibility-changed";
export const DIALOG_OPENED = "dialog-opened";