import Handlebars from "handlebars";

$.widget("custom.infoSidebar", {
    options: {
        attributes: [],
        header: undefined,
        events: {},
        templateDiv: '#event-details-template',
        template: 'view-event-details',
        contentTemplate: 'view-event-attributes',
        contentDiv: '#view-event-sidebar-content'
    },

    _create: function(){
        // render the main template with the header and buttons
        this._renderEmptySidebar();
        // initialize the event handlers
        for(let [id, events] of Object.entries(this.options.events)){
            this._on($(id), events);
        }
    },
    _renderEmptySidebar: function(){
        let template = Handlebars.compile(`{{>${this.options.template}}}`);
        let htmlContent = template({attributes: null, header: null});
        $(this.options.templateDiv).html(htmlContent);
    },

    updateSidebar(attributes, header){
        this._updateHeader(header);
        this._updateAttributes(attributes);
        this._show($(this.options.templateDiv));
    },

    _updateAttributes: function(attributes){
        this.options.attributes = attributes;
        // update the data in the template
        let template = Handlebars.compile(`{{>${this.options.contentTemplate}}}`);
        let htmlContent = template({attributes: this.options.attributes});
        $(this.options.contentDiv).html(htmlContent);
    },

    _updateHeader: function(header){
        this.options.header = header;
        $('#view-event').html(header);
    },

    isEmpty: function(){
        // check if the content is empty
        return $(`#${this.options.template}`).is(":hidden");
    },

    clear: function(){
        $('#view-event').html('');
        $(this.options.contentDiv).html('');
        this._hide($(this.options.templateDiv));
    },
})