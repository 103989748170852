import {Graticule} from "ol/layer";
import {Stroke} from "ol/style";
import {GRATICULE_VISIBILITY_CHANGED} from "../event-constants";

const GRATICULE_COLOR = 'rgba(0, 0, 0, 1)';

export const graticuleLayer = new Graticule({
    // the style to use for the lines, optional.
    strokeStyle: new Stroke({
        color: GRATICULE_COLOR,
        width: 1,
        lineDash: [0.5, 4],
    }),
    showLabels: true,
    visible: false,
    wrapX: true,
});

$(document).on(GRATICULE_VISIBILITY_CHANGED, setGraticuleVisibility);

function setGraticuleVisibility(e){
    graticuleLayer.setVisible(e.visible);
}