import {DEFAULT_YEAR, MIN_YEAR} from "../constants";
import Handlebars from "handlebars";
import {FILTERS_UPDATED, ON_EVENT_TYPES_READY} from "../event-constants";

$.widget("custom.advancedSearch", {
    options: {
        defaultYear: DEFAULT_YEAR,
        minYear: MIN_YEAR,
        template: "{{> advanced-search-template}}",
        templateID: 'search-filters',
        dialogTemplate: "{{> dialog-template}}",
        actionsTemplate: "{{> advanced-search-actions}}",
        dialogTitle: "Advanced Search"
    },

    _create: function(){
        this.defaultFilters = {
            eventTypes: {},
            duration_start: this.options.minYear,
            duration_end: this.options.defaultYear,
        };

        this.appliedFilters = {...this.defaultFilters};

        this._on(document, {
            [FILTERS_UPDATED]: this._syncEventTypesWithSettings,
            [ON_EVENT_TYPES_READY]: this._loadEventTypes
        });

    },

    _loadEventTypes: function(e){
        this._initializeEventTypeFilters(e.types);
        this._render(e.types);
        this._initializeEventListeners();
    },

    _initializeEventTypeFilters: function(eventTypes){
        for(let type of eventTypes){
            this.appliedFilters.eventTypes[type.id] = true;
            this.defaultFilters.eventTypes[type.id] = true;
        }
    },

    _render(eventTypes){

        let contentTemplate = Handlebars.compile(this.options.template);
        let htmlContent = contentTemplate({
            duration_start: this.appliedFilters.duration_start,
            duration_end: this.appliedFilters.duration_end,
            eventTypes
        });

        let actionsTemplate = Handlebars.compile(this.options.actionsTemplate);
        let actionsContent = actionsTemplate();

        let dialogTemplate = Handlebars.compile(this.options.dialogTemplate);
        let dialogContent = dialogTemplate({
            dialogID: this.options.templateID,
            dialogTitle: this.options.dialogTitle,
            dialogContent: htmlContent,
            dialogActions: actionsContent
        });

        $('#dialog-container').append(dialogContent);
        this._hide($(`#${this.options.templateID}`));
    },

    _initializeEventListeners: function(){
        // event listeners
        this._on('#search-filter-button', {
            click: this._handleClickSearchFilterEvent
        });

        this._on('#search-duration-start', {
            change: this._handleChangeStartDuration
        });

        this._on('#search-duration-end', {
            change: this._handleChangeEndDuration
        });

        this._on(`#${this.options.templateID}`, {
            'click #close-dialog': this.closeDialog
        });

        this._on('#reset-advanced-search', {
            click: this._handleResetSearchFilters
        });

        this._on('.event-type-filter-checkbox', {
            change: this._handleChangeSearchCriteria
        });
    },

    // Handle the event where the event type settings are changed
    _syncEventTypesWithSettings: function(e){
        this.appliedFilters.eventTypes = e.eventTypes;
    },

    _handleChangeStartDuration: function(e){
        this.appliedFilters.duration_start = $(e.target).attr('value');
    },

    _handleChangeEndDuration: function(e){
        this.appliedFilters.duration_end = $(e.target).attr('value');
    },

    _handleResetSearchFilters: function(){
        this.appliedFilters = {...this.defaultFilters};
    },

    _handleChangeSearchCriteria: function(e){
        let eventTypeID = $(e.target).val();
        this.appliedFilters.eventTypes[eventTypeID] = $(e.target).prop('checked');
    },

    _handleClickSearchFilterEvent: function(){
        $('#search-duration-start').val(this.appliedFilters.duration_start);
        $('#search-duration-end').val(this.appliedFilters.duration_end);

        //update each event type checkbox based on the applied filters.
        for(let type of Object.keys(this.appliedFilters.eventTypes)){
            let element = $(`#event-type-${type}-search-filter`);
            element.prop('checked', this.appliedFilters.eventTypes[type]);
        }

        this._handleOpenAdvancedSearch();
    },

    _handleOpenAdvancedSearch: function(){
        this._show($(`#${this.options.templateID}`));
    },

    getAppliedFilters: function(){
        return this.appliedFilters;
    },

    closeDialog: function(){
        this._hide($(`#${this.options.templateID}`));
    },
});

export const advancedSearchComponent = $('#dialog-container').advancedSearch({}).data("custom-advancedSearch");