import {ON_EDIT_TILES_PRESSED} from "../event-constants";
import {centerMapOnEvent} from "../Map";
import {getEventData} from "../events/ViewEvent";

export function initializeRecenterEvent(){
    // add event listeners
    $(document).on(ON_EDIT_TILES_PRESSED, showButtonOnEditTiles);
    $('#recenter-event').click(centerOnFeature);
}

function showButtonOnEditTiles(){
    $('#recenter-event').removeClass('hidden')
}

function centerOnFeature(){
    let event = getEventData();
    centerMapOnEvent(event);
}

export function handleHideRecenterButton(){
    $('#recenter-event').addClass('hidden');
}