import {initializeEventTypes} from "./control/NewEvent";
import {initializeYearSlider} from "./control/YearSlider";
import {initializeFeaturePopup} from "./control/FeaturePopup";
import {handleInitializeFillSizeSlider} from "./control/BrushSize";
import {initializeDiscardChangesDialog} from "./control/DiscardChanges";
import {initializeRecenterEvent} from "./control/RecenterEvent";
import './components/AdvancedSearch';
import './components/MapSettings';
import './components/CoordinateSearch';
import './events/HoverDetails';
import './control/InfoPopup';
import './components/ConfirmDelete';
import './components/InfoSidebar';
import {initializeViewEvent, performDeleteRequest} from "./events/ViewEvent";
import {ON_CANCEL_DELETE_EVENT} from "./event-constants";
import './components/VerticalSlider';
import './components/YearInput';
import './components/SelectEvent';
import './components/WelcomeMessage';

$('#message-container').welcomeMessage({});

$('#delete-confirmation').confirmDelete({
    confirmButton: "#confirm-delete",
    cancelButton: "#cancel-delete",
    confirmDeleteFunction: performDeleteRequest,
    cancelDeleteFunction: () => $(document).trigger(ON_CANCEL_DELETE_EVENT)
});

initializeEventTypes();
initializeYearSlider();
initializeFeaturePopup();
handleInitializeFillSizeSlider();
initializeDiscardChangesDialog();
initializeRecenterEvent();
initializeViewEvent();

$('#event-hover-container').eventDialog({eventID: null, dialogElement: 'event-details-dialog'});
