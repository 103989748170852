import {callAPI} from "./model/Request";
import Handlebars from "handlebars";
import {advancedSearchComponent} from "./components/AdvancedSearch";
import {SHOW_SEARCH_RESULTS, HIDE_SEARCH_RESULTS} from "./event-constants";

let filters;
let matches = 0;
const MAX_RESULTS = 100;
let isListOpen = false;

// hide the dummy autocomplete wrapper
$('.autocomplete-wrapper-placeholder').hide();

// function to render the search dropdown list
const handleRenderResultList = (list, data) => {
    let durationStart = filters.duration_start;
    let durationEnd = filters.duration_end;
    let template = Handlebars.compile("{{>search-list-template}}");
    let isRange = durationStart !== durationEnd;
    let isFound = data.results.length > 0;
    let isDurationStartNegative = durationStart < 0;
    let isDurationEndNegative = durationEnd < 0;
    let templateData = {
        isRange: isRange,
        durationStart: Math.abs(durationStart),
        isDurationStartNegative,
        durationEnd: Math.abs(durationEnd),
        isDurationEndNegative,
        isFound: isFound,
        results: data.results.length,
        matches: matches,
        query: data.query
    };

    let htmlContent = template(templateData);
    $(list).prepend(htmlContent);
}

// function to render each individual item in result list
const handleRenderResultItem = (item, data) =>{
    // Modify Results Item Style
    item.style = "display: flex; justify-content: space-between;";

    // Modify Results Item Content
    let isRange = filters.duration_start !== filters.duration_end;
    let year = data.value.year
    let template = Handlebars.compile("{{>search-list-item}}")
    let isYearNegative = year < 0;
    let templateData = {
        isRange: isRange,
        match: data.match,
        year: Math.abs(year),
        isYearNegative,
    }
    let htmlContent = template(templateData);
    item.innerHTML = htmlContent;
}

let autocomplete = new autoComplete({
    // options to handle querying the data
    data: {
        // function to send the search query and return a list of matched items
        src: async () => {
            let searchKeyword = $('#autoComplete').val();
            filters = advancedSearchComponent.getAppliedFilters();

            let body = {
                duration_start: filters.duration_start,
                duration_end: filters.duration_end,
                eventTypes: Object.entries(filters.eventTypes).filter(([key, value]) => value === true).map(([key]) => key),
                keyword: searchKeyword,
                limit: MAX_RESULTS
            }

            return await callAPI('search-event-autocomplete', body, null)
                .then((results) => {
                    matches = results.matches;
                    return results.events;
                })
        },
        // key in response to search against
        keys: ["name"]
    },
    // handle rendering the result list
    resultsList: {
        element: handleRenderResultList,
        noResults: true,
        tabSelect: true,
        maxResults: MAX_RESULTS
    },
    // handle rendering each item in the list
    resultItem: {
        element: handleRenderResultItem,
        highlight: true
    },
    events: {
        input: {
            focus: () => {
                if (autocomplete.input.value.length) autocomplete.start();
            },
            open: () => {
                $('.autocomplete-wrapper-placeholder').show();
                isListOpen = true;
                $('#top-controls').trigger($.Event(SHOW_SEARCH_RESULTS));
            },
            close: () => {
                $('.autocomplete-wrapper-placeholder').hide();
                isListOpen = false;
                $('#top-controls').trigger($.Event(HIDE_SEARCH_RESULTS));
            },
            blur: (e) => {
                // handle close event ourselves
                e.preventDefault();
            }
        }
    },
    submit: true
});

$(document).on('pointerdown', closeSearchIfClickOnMap)

function closeSearchIfClickOnMap(e){
    if(!isListOpen){
        return;
    }

    let parents = $(e.target).parents();
    let closeAutocomplete = true;
    for(let parent in parents){
        // if the parents are the top controls or the dialogs container, then we don't want to close the search
        if(['top-controls', 'dialog-container'].includes(parents[parent].id)){
           closeAutocomplete = false;
        }
    }


    if(closeAutocomplete){
        autocomplete.close();
    }
}

export function hideResults(){
    autocomplete.close();
}