import {getURL} from "../model/UrlBuilder";
import {TileWMS} from "ol/source";
import {createXYZ} from "ol/tilegrid";
import TileLayer from "ol/layer/WebGLTile";

let wmsURL = getURL('tapestry-wms-tiles-by-id');

export const editWMSLayer = new TileLayer({
    source: new TileWMS({
        url: wmsURL,
        params: {'LAYERS': 'events', 'TILED': true},
        tileGrid: createXYZ({}),
        transition: 0,
    }),
    visible: false,
    opacity: 0
})