import {callAPI} from "../model/Request";

let eventAttributes = {};
export function initializeEventAttributes(e){
    // e.types has all the types, we need to get all the attributes for each type and store them in eventAttributes
    e.types.forEach((type) => {
        callAPI('get-event-type-attributes', null, [type.id])
            .then((attributes) => {
                eventAttributes[type.id] = attributes;
            })
    })
}

export function getEventAttributes(eventTypeID){
    return eventAttributes[eventTypeID];
}