import Handlebars from "handlebars";
import {
    FILTERS_UPDATED,
    GRATICULE_VISIBILITY_CHANGED,
    ON_EVENT_TYPES_READY,
    OPACITY_CHANGED
} from "../event-constants";
import {wmsTileLayer} from "../layers/EventTileWMS";

$.widget("custom.mapSettings", {
    options: {
        template: "{{> map-settings-template}}",
        templateID: "filter-event-types",
        dialogTemplate: "{{> dialog-template}}",
        dialogTitle: "Settings"
    },

    _create: function(){
        this.appliedTypeFilters = {};
        this.stagedTypeFilters = {};

        this._on(document, {
            [ON_EVENT_TYPES_READY]: this._loadEventTypes
        });
    },

    _loadEventTypes: function(e){
        this._initializeFilters(e.types);
        this._render(e.types);
        this._initializeEventListeners();
        this._applyFiltersToLayer();
    },

    _initializeFilters: function(eventTypeList){
        for(let eventType of eventTypeList){
            this.appliedTypeFilters[`${eventType.id}`] = true;
        }

        this.stagedTypeFilters = {...this.appliedTypeFilters};
    },

    _render: function(eventTypes){
        let template = Handlebars.compile(this.options.template);
        let htmlContent = template({eventTypes});

        let dialogTemplate = Handlebars.compile(this.options.dialogTemplate);
        let dialogContent = dialogTemplate({
            dialogID: this.options.templateID,
            dialogTitle: this.options.dialogTitle,
            dialogContent: htmlContent
        });

        $('#dialog-container').append(dialogContent);
    },

    _initializeEventListeners: function(){
        this._on("#filter-event", {
            click: this._handleClickFilterEventsButton
        });

        this._on(".event-type-checkbox", {
            change: this._handleChangeFilter
        });

        this._on("#apply-filter", {
            click: function(e){
                this._handleClickFilterButton();
                this._triggerUpdateFilter(e);
                this._applyFiltersToLayer();
            }
        });

        this._on(`#${this.options.templateID}`, {
            'click #close-dialog': function(){
                this._handleResetFilters();
                this._handleResetFilterUI();
            }
        });

        this._on("#event-opacity", {
            input: this._opacityChanged
        });

        this._on("#show-graticule", {
            change: this._graticuleVisibilityChanged
        });
    },

    _handleChangeFilter: function(e){
        let eventTypeID = $(e.target).val();
        this.stagedTypeFilters[eventTypeID] = $(e.target).prop('checked');
    },

    _triggerUpdateFilter: function(){
        $(document).trigger($.Event(FILTERS_UPDATED, {eventTypes: this.appliedTypeFilters}));
    },

    _handleClickFilterEventsButton: function(){
        this._show($(`#${this.options.templateID}`));
    },

    _handleClickFilterButton: function(){
        this.appliedTypeFilters = {...this.stagedTypeFilters};
    },

    _applyFiltersToLayer: function(){
        let typesString = '';
        for(let eventType of Object.keys(this.appliedTypeFilters)){
            if(this.appliedTypeFilters[eventType]){
                if(typesString.length === 0){
                    typesString += `${eventType}`;
                }else{
                    typesString += `,${eventType}`;
                }
            }
        }

        wmsTileLayer.getSource().updateParams({'types': typesString});
        this.closeDialog();
    },

    _handleResetFilters: function(){
        this.stagedTypeFilters = {...this.appliedTypeFilters};
    },

    _handleResetFilterUI: function(){
        for(let type of Object.keys(this.appliedTypeFilters)){
            $(`#event-type-${type}-filter`).prop('checked', this.appliedTypeFilters[type]);
        }
        this.closeDialog();
    },

    _opacityChanged: function(e){
        let value = e.target.value;
        $(document).trigger($.Event(OPACITY_CHANGED, {opacity: value}));
    },

    _graticuleVisibilityChanged: function(e){
        let value = $(e.target).prop('checked');
        $(document).trigger($.Event(GRATICULE_VISIBILITY_CHANGED, {visible: value}));
    },

    closeDialog: function(){
        this._hide($(`#${this.options.templateID}`));
    },
});

export const mapSettings = $('#dialog-container').mapSettings({}).data("custom-mapSettings");