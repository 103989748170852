import {eventTypes} from "../control/NewEvent";
import Handlebars from "handlebars";
import {EVENT_SELECTED_FROM_DIALOG} from "../event-constants";

$.widget("custom.selectEvent", {
    options: {
        events: [],
        dialogTemplate: '{{> select-event-dialog}}',
        dialogContentTemplate: '{{> select-event-item}}',
        dialogID: 'select-event-dialog',
        anchorElementID: 'select-event-anchor'
    },

    _create: function(){
        // create hidden dialog element
        let template = Handlebars.compile(this.options.dialogTemplate)
        let htmlContent = template({attributes: [], mouseX: 0, mouseY: 0});
        $(this.element).append(htmlContent);
        this._on($(`#${this.options.dialogID}`), {
            'click .select-event-item': this._onDialogSelect
        })
    },

    _onDialogSelect: function(e){
        let eventID = $(e.currentTarget).attr('data-event-id');
        $(document).trigger($.Event(EVENT_SELECTED_FROM_DIALOG, {eventID: Number(eventID)}));
    },

    updateData: function(events, mouseX, mouseY){
        this.options.events = events;
        let attributes = this._getAttributes();
        this._render(attributes, mouseX, mouseY);
    },

    _render: function(attributes, mouseX, mouseY){
        // update the dialog element with the new attributes
        let template = Handlebars.compile(this.options.dialogContentTemplate)
        let htmlContent = template({attributes: attributes, mouseX, mouseY});
        $(`#${this.options.dialogID}`).html(htmlContent);
        this._moveDialog(mouseX, mouseY);
        this._showMenu();
    },

    _moveDialog: function (x, y){
        let element = document.getElementById(this.options.anchorElementID);
        element.style.left = `${x}px`;
        element.style.top = `${y}px`;
    },

    _getAttributes: function(){
        let attributes = [];

        // get the event type and event name
        for(let event of this.options.events){
            // each attribute is one row of data in the dialog
            let eventType = eventTypes.find((type) => type.id === event.event_type_id);
            attributes.push({eventType: eventType.name, event: event.name, eventID: event.id});
        }

        return attributes;
    },

    _showMenu: function(){
        const menuElement = document.body.querySelector(`#${this.options.dialogID}`);
        menuElement.show();
    },

    clear: function(){
        $(`#${this.options.dialogID}`).html("");
    }
})