import {DEFAULT_YEAR, MAX_YEAR, MIN_YEAR} from "../constants";
import YearInputStyles from '../../css/YearInput.css?raw';
import Handlebars from "handlebars";

const styleSheet = new CSSStyleSheet();
styleSheet.replaceSync(YearInputStyles);

const AD_IMAGE_SRC = '/images/ad.png';
const BC_IMAGE_SRC = '/images/bc.png';

class YearInput extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({mode: 'open'});
        this.shadowRoot.adoptedStyleSheets = [styleSheet];

        if(!this.hasAttribute('value')) {
            this.setAttribute('value', DEFAULT_YEAR);
        }

        if(!this.hasAttribute('min')){
            this.setAttribute('min', MIN_YEAR);
        }

        if(!this.hasAttribute('max')){
            this.setAttribute('max', MAX_YEAR);
        }

        let template = Handlebars.compile('{{> year-input-template}}');

        let htmlContent = template({
            controls: this.hasAttribute('controls'),
            centered: this.hasAttribute('centered'),
            label: this.getAttribute('label'),
            isYearNegative: parseInt(this.getAttribute('value')) < 0,
            AD_IMAGE_SRC,
            BC_IMAGE_SRC,
            readonly: this.hasAttribute('readonly'),
            value: Math.abs(parseInt(this.getAttribute('value'))),
            elevated: this.hasAttribute('elevated')
        });

        this.shadowRoot.innerHTML = htmlContent;

        // add event listeners
        if(this.hasAttribute('controls')){
            this.shadowRoot.getElementById('increment-year').addEventListener('click', this.incrementYearValue.bind(this));
            this.shadowRoot.getElementById('decrement-year').addEventListener('click', this.decrementYearValue.bind(this));
        }

        this.shadowRoot.getElementById('era-image').addEventListener('click', this.changeEra.bind(this));


        this.shadowRoot.getElementById('year-input').addEventListener('change', this.handleYearChanged.bind(this));
        this.shadowRoot.getElementById('year-input').addEventListener('keydown', this.handleKeyDown.bind(this));
        this.shadowRoot.getElementById('year-input').addEventListener('input', this.handleYearInput.bind(this));
        const observer = new MutationObserver(this.updateYear.bind(this));
        observer.observe(this, {attributes: true, attributeFilter: ['value']});
    }

    incrementYearValue(){
        let year = parseInt(this.getAttribute('value'), 10);
        year++;
        this.setAttribute('value', this.checkYearBetweenMinMax(year));
        this.dispatchEvent(new Event('change'));
    }

    decrementYearValue(){
        let year = parseInt(this.getAttribute('value'), 10);
        year--;
        this.setAttribute('value', this.checkYearBetweenMinMax(year));
        this.dispatchEvent(new Event('change'));
    }

    // change year value in input field based on parent element value
    updateYear(){
        const year = parseInt(this.getAttribute('value'), 10);
        if(isNaN(year)){
            this.setAttribute('value', null);
            this.shadowRoot.getElementById('era-image').innerHTML = '';
            return;
        }

        this.shadowRoot.getElementById('year-input').value = Math.abs(year);

        if(year < 0){
            this.shadowRoot.getElementById('era-image').src = BC_IMAGE_SRC;
        }else{
            this.shadowRoot.getElementById('era-image').src = AD_IMAGE_SRC;
        }
    }

    checkYearBetweenMinMax(year){
        if(year < this.getAttribute('min')){
            return this.getAttribute('min');
        } else if(year > this.getAttribute('max')){
            return this.getAttribute('max');
        }

        return year;
    }

    handleYearChanged(e){
        let year = parseInt(e.target.value, 10);
        let currentYear = parseInt(this.getAttribute('value'), 10);

        if (isNaN(year)) {
            this.setAttribute('value', null);
        }else{
            if(currentYear < 0){
                year = -year;
            }

            this.setAttribute('value', this.checkYearBetweenMinMax(year));
        }

        this.dispatchEvent(new Event('change'));
    }

    handleYearInput(e){
        let year = parseInt(e.target.value, 10);
        let currentYear = parseInt(this.getAttribute('value'), 10);

        if(currentYear < 0){
            year = -year;
        }

        // if the value is a number, set the attribute value
        if(!isNaN(year)){
            this.setAttribute('value', this.checkYearBetweenMinMax(year));
        }
    }

    handleKeyDown(e){
        if(e.key === 'ArrowUp' || e.key === 'ArrowDown'){
            e.preventDefault();
            let year = parseInt(this.getAttribute('value'), 10);
            if(isNaN(year)){
                year = null;
            }

            if(e.key === 'ArrowUp') {
                year++;
            }else if (e.key === 'ArrowDown'){
                year--;
            }

            this.setAttribute('value', this.checkYearBetweenMinMax(year));

            this.dispatchEvent(new Event('change'));
        }
    }

    changeEra(){
        // check if this input is readonly
        if(this.hasAttribute('readonly')){
            return;
        }

        // check current era, if its positive change to negative and vice versa
        let currentYear = parseInt(this.getAttribute('value'), 10);
        if(currentYear < 0){
            currentYear = Math.abs(currentYear);
        }else{
            currentYear = -currentYear;
        }

        this.setAttribute('value', this.checkYearBetweenMinMax(currentYear));

        this.dispatchEvent(new Event('change'));
    }
}

customElements.define('year-input', YearInput);
