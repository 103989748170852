import {
    ATTRIBUTE_LABELS,
    DELETE_EVENT_ACTION, DELETE_EVENT_GROUP_ACTION, DELETE_EVENT_GROUP_MESSAGE, DELETE_EVENT_MESSAGE,
    ERROR_PROCESSING_DELETE, EVENT_NOT_FOUND_IN_YEAR,
    INVALID_DELETE_ACTION,
    PLAIN_TEXT_FIELD,
    RICH_TEXT_FIELD,
    SELECTED_LANGUAGE_CODE,
    SERVER_PROCESSING_DELETE,
} from "../constants";
import {
    EVENT_SELECTED_FROM_DIALOG,
    ON_CLOSE_VIEW_EVENT,
    ON_CONFIRM_DELETE_EVENT,
    ON_DELETE_EVENT,
    ON_EDIT_ATTRIBUTES_PRESSED,
    ON_EDIT_TILES_PRESSED,
    ON_EVENT_CLICKED, YEAR_CHANGED
} from "../event-constants";
import {callAPI} from "../model/Request";
import {centerMapOnEvent} from "../Map";
import {renderErrorMessage, renderInfoMessage} from "../control/InfoPopup";
import {getSelectedYear} from "../control/YearSlider";
import {getEventAttributes} from "./EventAttributes";
import {eventTypes} from "../control/NewEvent";

let deleteAction;
let eventDetails;
let eventName;
let groupID;

const eventListeners = {
    '#edit-event-attributes': {
        'click': ()=>$(document).trigger(ON_EDIT_ATTRIBUTES_PRESSED),
    },
    '#close-view-event-sidebar': {
        'click': () => $(document).trigger(ON_CLOSE_VIEW_EVENT),
    },
    '#edit-event-tiles': {
        'click': () => $(document).trigger(ON_EDIT_TILES_PRESSED),
    },
    '#center-event': {
        'click': handleCenterEvent,
    },
    '#toggle-delete-menu': {
        'click': toggleDeleteMenu
    },
    '#delete-event-menu': {
        'close-menu': deleteActionSelected,
    },
    '#view-event-sidebar-content': {
        'click #next-event': getAdjacentEvent,
        'click #previous-event': getAdjacentEvent,
    }
}

const deleteActionMapping = {
    [DELETE_EVENT_ACTION]: DELETE_EVENT_MESSAGE,
    [DELETE_EVENT_GROUP_MESSAGE]: DELETE_EVENT_GROUP_MESSAGE
}

export function initializeViewEvent(){
    $('#event-details-template').infoSidebar({attributes: null, header: null, events: eventListeners});
    $(document.body).selectEvent({});
    $(document).on(YEAR_CHANGED, getGroupEventFromYear);
    $(document).on(EVENT_SELECTED_FROM_DIALOG, eventSelectedFromDialog);
}

// prepare the data for this events attributes
function prepareEventAttributes(eventTypeAttributes, eventTypeID){
    if (eventTypeAttributes === null){
        return;
    }

    let freeFormAttributes = getEventAttributes(eventTypeID);

    let attributes = [];

    freeFormAttributes.forEach((attribute) => {
        if(attribute.name in eventTypeAttributes){
            attributes.push({
                attributeName: attribute.label[SELECTED_LANGUAGE_CODE],
                attributeValue: eventTypeAttributes[attribute.name][SELECTED_LANGUAGE_CODE],
                attributeType: attribute.type,
            });
        }
    });

    return attributes;
}

// prepare all event attributes to show to a user
async function prepareViewEventData(event){
    // prepare list of objects 'attributes' with attributeName and attributeValue keys for each datapoint
    let attributes = [];
    if(event.name !== undefined){
        attributes.push({attributeName: ATTRIBUTE_LABELS['Name'][SELECTED_LANGUAGE_CODE], attributeValue: event.name, attributeType: PLAIN_TEXT_FIELD});
    }

    attributes.push({
        duration: [
            {attributeValue: Math.abs(event.duration_start), isYearNegative: event.duration_start < 0, isYear: true},
            {attributeValue: ' - '},
            {attributeValue: Math.abs(event.duration_end), isYearNegative: event.duration_end < 0, isYear: true}
        ],
        attributeName: ATTRIBUTE_LABELS['duration'][SELECTED_LANGUAGE_CODE],
        isYear: true
    });

    if(event.description !== undefined){
        attributes.push({attributeName: ATTRIBUTE_LABELS['Description'][SELECTED_LANGUAGE_CODE], attributeValue: event.description, attributeType: RICH_TEXT_FIELD});
    }

    let isYearNegative = event.year < 0;

    let adjacentEvents = await getAdjacentEvents();

    attributes.push({
        attributeName: ATTRIBUTE_LABELS['Year'][SELECTED_LANGUAGE_CODE],
        attributeValue: Math.abs(event.year),
        attributeType: PLAIN_TEXT_FIELD,
        isYear: true,
        isYearNegative,
        ...adjacentEvents
    });

    if(event.attributes !== undefined && event.event_type_id !== undefined){
        attributes = [...attributes, ...prepareEventAttributes(event.attributes, event.event_type_id)];
    }

    return attributes;
}

async function updateEventAttributes(eventData){
    eventDetails = eventData || eventDetails;
    eventName = eventData.name || eventName;
    groupID = eventData.event_group_id || groupID;

    let attributes = await prepareViewEventData(eventData);
    $('#event-details-template').infoSidebar('updateSidebar', attributes, eventData.header);
}

async function showEventDetails(eventToShow) {
    let event = eventToShow;
    eventDetails = event
    eventName = event.name;
    groupID = event.event_group_id;

    let attributes = await prepareViewEventData(event);
    let header = `${eventTypes[event.event_type_id - 1].name} Details`
    $('#event-details-template').infoSidebar('updateSidebar', attributes, header);
    $(document).trigger(ON_EVENT_CLICKED);
}

export function getEventDetailsByPixels(e){
    let year = getSelectedYear();
    callAPI('get-event-on-year-at-xy', null, [year, ...e.coordinates])
        .then(async (events) => {
            if(events.length > 1){
                eventDetails = events;
                $(document.body).selectEvent('updateData', events, e.pixel[0], e.pixel[1]);
            }else {
                await showEventDetails(events[0]);
            }
        })
}

async function eventSelectedFromDialog(e){
    let eventID = e.eventID;
    let event = eventDetails.find((event) => event.id === eventID);
    await showEventDetails(event);
    $(document.body).selectEvent('clear');

}

/**
 * Get the event from the year set on the year slider or input field
 * @param e
 */
function getGroupEventFromYear(e){
    // check if content is showing or not
    if($('#event-details-template').infoSidebar('isEmpty')){
        return;
    }

    if(!['year-slider', 'year-input'].includes(e.target.activeElement.id)){
        return;
    }

    let year = e.year;
    callAPI('get-event-by-event-group-and-year', null, [groupID, year])
        .then((event) => {
            // rerender the view as we do below, but with the new event gotten from this request, chances are it can be empty as well, so rendering needs to handle that
            if(typeof event === 'string'){
                event = {
                    year: year,
                    header: EVENT_NOT_FOUND_IN_YEAR(eventName),
                }
            }

            updateEventAttributes(event);
        })
}

function getAdjacentEvent(e){
    let adjacentEventID = $(e.currentTarget).attr('data-event-id');
    let adjacentYear = $(e.currentTarget).attr('data-event-year');
    callAPI('get-event-by-id', null, [adjacentEventID])
        .then((adjacentEvent) => {
            // change the year to the year of the adjacent event
            $(document).trigger($.Event(YEAR_CHANGED, {year: adjacentYear}));
            adjacentEvent.header = `${eventTypes[adjacentEvent.event_type_id-1].name} Details`
            updateEventAttributes(adjacentEvent);
        })
}

async function getAdjacentEvents(){
    return callAPI('get-adjacent-event-for-event-group-in-year', null, [groupID, getSelectedYear()])
        .then((adjacentEvents) => {
            return adjacentEvents;
        })
}

function toggleDeleteMenu(){
    const menuElement = $('#delete-event-menu');
    menuElement.prop('open', !menuElement.prop('open'));
}

function deleteActionSelected(e){
    deleteAction = $(e.target).attr('id');

    $('#delete-message').html(deleteActionMapping[deleteAction]);

    switch(deleteAction){
        case DELETE_EVENT_ACTION:
            $(document).trigger($.Event(ON_DELETE_EVENT, {deleteAction: deleteAction, id: eventDetails.id}));
            break;
        case DELETE_EVENT_GROUP_ACTION:
            $(document).trigger($.Event(ON_DELETE_EVENT, {deleteAction: deleteAction, id: eventDetails.event_group_id}));
            break;
        default:
            throw new Error(INVALID_DELETE_ACTION);
    }
}

function handleCenterEvent(){
    centerMapOnEvent(eventDetails);
}

// handling closing the view event details modal, happens after transitioning out of view event details
export function closeViewEventHandler(){
    // remove all attributes related to this event
    $('#event-details-template').infoSidebar('clear');
    eventDetails = null;
    groupID = null;
    eventName = null;
}

export function performDeleteRequest(deleteAction, id){
    callAPI(deleteAction, null, [id])
        .then(() => {
            eventDetails = null;
            $(document).trigger(ON_CONFIRM_DELETE_EVENT);
            renderInfoMessage({text: SERVER_PROCESSING_DELETE});
        })
        .catch(() => {
            renderErrorMessage({text: ERROR_PROCESSING_DELETE});
        })
}

export function getEventID(){
    return eventDetails.id;
}

export function getEventData(){
    return eventDetails;
}