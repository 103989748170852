import Handlebars from "handlebars";

// import the templates
import eventTypeMenu from '/templates/event-type-menu.handlebars?url&raw';
import newEventColors from '/templates/create-event-color-options.handlebars?url&raw';
import eventAttributeInput from '/templates/event-attributes-input.handlebars?url&raw';
import viewEventDetails from '/templates/view-event-details.handlebars?url&raw';
import editEventForm from '/templates/edit-event-form.handlebars?url&raw';
import searchListTemplate from '/templates/search-list-template.handlebars?url&raw';
import searchListItemTemplate from '/templates/search-list-item.handlebars?url&raw';
import eventDetailsDialog from '/templates/event-details-dialog.handlebars?url&raw';
import viewEventAttributes from '/templates/view-event-attributes.handlebars?url&raw';
import coordinateSearchMenu from '/templates/coordinate-search-menu.handlebars?url&raw';
import yearInputTemplate from '/templates/year-input-template.handlebars?url&raw';
import selectEventDialog from '/templates/select-event-dialog.handlebars?url&raw';
import selectEventItem from '/templates/select-event-item.handlebars?url&raw';
import welcomeMessage from '/templates/welcome-message.handlebars?url&raw';
import advancedSearchTemplate from '/templates/advanced-search-template.handlebars?url&raw';
import advancedSearchActions from '/templates/advanced-search-actions.handlebars?url&raw';
import mapSettingsTemplate from '/templates/map-settings-template.handlebars?url&raw';
import dialogTemplate from '/templates/dialog-template.handlebars?url&raw';

// register the templates
Handlebars.registerPartial('event-type-menu', eventTypeMenu);
Handlebars.registerPartial('create-event-color-options', newEventColors);
Handlebars.registerPartial('event-attributes-input', eventAttributeInput);
Handlebars.registerPartial('view-event-details', viewEventDetails);
Handlebars.registerPartial('edit-event-form', editEventForm);
Handlebars.registerPartial('search-list-template', searchListTemplate);
Handlebars.registerPartial('search-list-item', searchListItemTemplate);
Handlebars.registerPartial('event-details-dialog', eventDetailsDialog);
Handlebars.registerPartial('view-event-attributes', viewEventAttributes);
Handlebars.registerPartial('coordinate-search-menu', coordinateSearchMenu);
Handlebars.registerPartial('year-input-template', yearInputTemplate);
Handlebars.registerPartial('select-event-dialog', selectEventDialog);
Handlebars.registerPartial('select-event-item', selectEventItem);
Handlebars.registerPartial('welcome-message', welcomeMessage);
Handlebars.registerPartial('advanced-search-template', advancedSearchTemplate);
Handlebars.registerPartial('map-settings-template', mapSettingsTemplate);
Handlebars.registerPartial('advanced-search-actions', advancedSearchActions);
Handlebars.registerPartial('dialog-template', dialogTemplate);

Handlebars.registerHelper('replace', function(find, replace, options){
    var string = options.fn(this);
    return string.replaceAll(find, replace);
})

Handlebars.registerHelper('isZero', function(value){
    return value === 0;
})

Handlebars.registerHelper('isEqual', function(value1, value2){
    return value1 === value2;
})