import TileLayer from 'ol/layer/WebGLTile.js';
import TileWMS from 'ol/source/TileWMS.js';
import {createXYZ} from 'ol/tilegrid.js';
import {getURL} from "../model/UrlBuilder";
import {getSelectedYear} from "../control/YearSlider";
import {DEFAULT_LAYER_OPACITY, DEFAULT_YEAR} from "../constants";

let wmsURL = getURL('tapestry-wms-tiles');

const REFRESH_DELAY = 2000;

export const wmsTileLayer = new TileLayer({
    source: new TileWMS({
      url: wmsURL,
      params: {'LAYERS': 'events', 'TILED': true, 'year': DEFAULT_YEAR,'zoom': 1, 'search': '', 'types': '', 'showEventOverlapMask': true},
      // Source tile grid (before reprojection)
      tileGrid: createXYZ({}),
      tileLoadFunction: (imageTile, src) => {
        let year = getSelectedYear();
        imageTile.getImage().src = `${src}`;
      },
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
  opacity: DEFAULT_LAYER_OPACITY
})

export function refreshEventLayer(){
  setTimeout(
      () => wmsTileLayer.getSource().updateParams({'timestamp': Date.now()}),
      REFRESH_DELAY
  );
}

export function setLayerOpacity(e){
  wmsTileLayer.setOpacity(e.opacity);
}