const DEFAULT_TIMEOUT = 3000;

let defaultToastOptions = {
    showHideTransition: "fade",
    allowToastClose: true,
    hideAfter: DEFAULT_TIMEOUT
}

export function renderInfoMessage(toastOptions){
    toastOptions.icon = "info";
    toastOptions.stack = 3;

    $.toast(toastOptions);
}

export function renderErrorMessage(toastOptions){
    toastOptions.icon = "error";
    toastOptions.stack = false;

    $.toast(toastOptions);
}

export function createPopupOptions(options){
    // Combine the default options for this toast with the toastOptions, if any of the keys are defined,
    // they will replace the value in defaultToastOptions
    return {...defaultToastOptions, ...options};
}