import {wmsTileLayer} from "../layers/EventTileWMS";
import {callAPI} from "../model/Request";
import {YEAR_CHANGED} from "../event-constants";
import {EVENT_NOT_FOUND_MESSAGE} from "../constants";
import {centerMapOnEvent} from "../Map";
import {renderErrorMessage} from "./InfoPopup";
import {hideResults} from "../autocomplete-extend";
import {getSelectedYear} from "./YearSlider";

export function selectEventFromSearch(e){
    // get selected event from database
    callAPI('get-event-by-id', null, [e.detail.selection.value.id])
        .then((event) => {
            // set extent of map to show just the event:
            centerMapOnEvent(event);

            let year = event.year;
            let currentYear = getSelectedYear();
            if(year !== currentYear){
                $(document).trigger($.Event(YEAR_CHANGED, {year: year}));
            }
        })
        .catch((err) => {
            renderErrorMessage({text: EVENT_NOT_FOUND_MESSAGE});
        })
}

// if enter has been pressed, close the modal and apply search
export function handleSearchInputKeyDown(e){
    if(e.key === 'Enter'){
        refreshData();
        hideResults();
    }
}

function refreshData() {
    let search = $('#autoComplete').val();
    wmsTileLayer.getSource().updateParams({'search': search});
}