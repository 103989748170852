import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {FEATURE_STROKE_WIDTH} from "../constants";
import {Point} from "ol/geom";
import Feature from "ol/Feature";
import {createXYZ} from "ol/tilegrid";
import {Fill, Stroke, Style, Circle} from "ol/style";

const POINT_COLOR = 'rgba(255, 0, 0, 1)';

let pointLayerSource = new VectorSource({
    wrapX: true,
    tileGrid: createXYZ()
});

export const pointLayer = new VectorLayer({
    source: pointLayerSource,
    visible: true,
    style: new Style({
        image: new Circle({
            radius: FEATURE_STROKE_WIDTH * 2,
            fill: new Fill({
                color: POINT_COLOR,
            }),
            stroke: new Stroke({
                color: POINT_COLOR,
                width: FEATURE_STROKE_WIDTH,
            }),
        }),
        zIndex: Infinity,
    })
})

export function addPointToLayer(coordinates){
    let feature = new Feature({geometry: new Point(coordinates)});
    pointLayerSource.addFeature(feature);
}

export function removePointFromLayer(){
    pointLayerSource.clear();
}

window.pointLayer = pointLayer;