import Map from "ol/Map";
import View from "ol/View";
import {defaults as defaultInteractions} from "ol/interaction";
import {backgroundLayer} from "./layers/Background"
import {drawLayer} from "./layers/Draw";
import {eventLayer} from "./layers/Event";
import {MAP_DRAG_OFF, MAP_DRAG_ON, ON_MAP_CLICK_EVENT} from "./event-constants";
import DragPan from 'ol/interaction/DragPan.js';

import {wmsTileLayer} from "./layers/EventTileWMS"
import {maxZoom, tileWMSGrid} from "./layers/Common";
import {TileDebug} from "ol/source";
import TileLayer from "ol/layer/WebGLTile";
import {generateInBoundCoordinates, transformTileCoordinatesToEPSG3857} from "./features";
import {editWMSLayer} from "./layers/EditTileWMS";
import {pointLayer} from "./layers/PointLayer";
import {graticuleLayer} from "./layers/GraticuleLayer";
import {getCurrentStateProperty} from "./state/transitions";

// Drag pan interaction control
let mapDrag = null;

let eventOnPointer = null;

// controls for the map
const controls = [

]

// layers we use on the map
const layers = [
    backgroundLayer,
    editWMSLayer,
    wmsTileLayer,
    pointLayer,
    graticuleLayer,
    eventLayer,
    drawLayer,
]

// the map view object
const view = new View({
    center: [0, 0],
    zoom: 2,
    constrainResolution: true,
    maxZoom: 12,
})

const map = new Map({
    target: "map",
    controls: controls,
    interactions: defaultInteractions({shiftDragZoom: false}),
    layers: layers,
    view: view
});

// map.getView().on('change:resolution', () => {
//     onZoomChange(map.getView().getZoom());
// })

// these events only happen if the user is in the view mode (not edit mode)
map.on('pointermove', function (evt) {
    if(getCurrentStateProperty('changePointerOnHover')){
        const data = wmsTileLayer.getData(evt.pixel);
        const hit = data && data[3] > 0; // transparent pixels have zero for data[3]
        map.getTargetElement().style.cursor = hit ? 'pointer' : '';
    }
});

map.on('singleclick', function (evt) {
    if(getCurrentStateProperty('selectEventOnClick')){
        const data = wmsTileLayer.getData(evt.pixel);
        const hit = data && data[3] > 0; // transparent pixels have zero for data[3]
        if(hit) {
            let tileCoord = tileWMSGrid.getTileCoordForCoordAndZ(evt.coordinate, maxZoom - 1);
            let boundedCoordinates = generateInBoundCoordinates(tileCoord);
            $(document).trigger($.Event(ON_MAP_CLICK_EVENT, {coordinates: boundedCoordinates, pixel: evt.pixel}));
        }
    }
});

function setDragPan(){
    map.interactions.forEach((control) => {
        if(control instanceof DragPan){
            mapDrag = control;
        }
    })
}

function disableDragPan(){
    mapDrag.setActive(false);
}

function enableDragPan(){
    mapDrag.setActive(true);
}

$(document).ready(setDragPan);
$(document).on(MAP_DRAG_ON, enableDragPan);
$(document).on(MAP_DRAG_OFF, disableDragPan);

export function centerMapOnEvent(event){
    let [minX, minY] = transformTileCoordinatesToEPSG3857(event.img_offx, event.img_offy + event.img_sizey);
    let [maxX, maxY] = transformTileCoordinatesToEPSG3857(event.img_offx + event.img_sizex, event.img_offy);

    // set the extent of the map to the event with some padding
    map.getView().fit([minX, minY, maxX, maxY]);
}

export function restrictMapWidth(){
    $('#sidebar-container').removeClass('hidden');
}

export function resetMapWidth(){
    $('#sidebar-container').addClass('hidden');
}

// DEBUGGING CODE
let debugLayer = new TileLayer({
    source: new TileDebug()
});

function showDebugTiles(){
    map.addLayer(debugLayer);
}

function hideDebugTiles(){
    map.removeLayer(debugLayer);
}

window.showDebugTiles = showDebugTiles;
window.hideDebugTiles = hideDebugTiles;

export {map};