import Handlebars from "handlebars";

$.widget("custom.welcomeMessage", {
    options: {
        template: '{{> welcome-message}}'
    },
    _create: function(){
        // check localstorage for ignoreMessage key, if it exists, don't render anything
        if(localStorage.getItem('ignoreMessage') === 'true'){
            return;
        }

        // otherwise render the welcome message
        this._render();
        // set the event listeners
        // one for close
        // one for the little checkbox to ignore the message next time the page is loaded
        this._on("#ignore-message", {
            'input': this._ignoreMessageChecked
        });
        this._on("#close-message", {
            'click': this._closeMessage
        });

        $(document).ready(() => {
            // Set the z-index of the dialog scrim div to be higher than everything else
            this._setDialogZIndex();
            // Remove focus if the element inadvertently gains it
            $('#ignore-message').blur();
        });
    },

    _render: function(){
        let template = Handlebars.compile(this.options.template);
        let htmlContent = template();
        this.element.html(htmlContent);
    },

    _setDialogZIndex: function(){
        // set the z-index of the dialogs scrim div to be higher than everything else
        // the dialog scrim div exists within its shadowdom, so we need to select it externally
        document
            .querySelector('#welcome-dialog')
            .shadowRoot.querySelector('.scrim').style.zIndex = 1000;
    },

    _ignoreMessageChecked: function(){
        // if the checkbox is checked, set localstorage key ignoreMessage to true
        localStorage.setItem('ignoreMessage', 'true');
    },

    _closeMessage: function(){
        // remove the welcome message from the DOM
        $(this.element).remove();
    }
})